define('wnyc-web-client/components/centennial/event-card/component', ['exports', 'moment'], function (exports, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    image: Ember.computed.readOnly('event.attributes.imageMain'),
    month: Ember.computed('event.attributes.start-date', function () {
      return (0, _moment.default)(this.get('event.attributes.startDate')).format('MMM');
    }),
    day: Ember.computed('event.attributes.startDate', function () {
      return (0, _moment.default)(this.get('event.attributes.startDate')).format('DD');
    }),
    startTime: Ember.computed('event.attributes.{startDate,startTime}', function () {
      return (0, _moment.default)(this.get('event.attributes.startDate') + ' ' + this.get('event.attributes.startTime')).format('h:mm A');
    }),
    tags: Ember.computed('event.attributes.tags', function () {
      return this.get('event.attributes.tags').split(', ');
    }),
    isInPerson: Ember.computed('tags', function () {
      return this.get('tags').includes('in-person');
    }),
    isLiveStream: Ember.computed('tags', function () {
      return this.get('tags').includes('live_stream');
    }),
    eventUrl: Ember.computed('event.attributes.{startDate,slug}', function () {
      var year = (0, _moment.default)(this.get('event.attributes.startDate')).format('YYYY');
      var month = (0, _moment.default)(this.get('event.attributes.startDate')).format('MMM').toLowerCase();
      var day = (0, _moment.default)(this.get('event.attributes.startDate')).format('DD');
      var slug = this.get('event.attributes.slug');
      return '/events/wnyc-events/' + year + '/' + month + '/' + day + '/' + slug + '/';
    })

  });
});